/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../components/elements'

const Terms = ({ data }) => {

  const page = data.prismic.terms

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section >
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full', 4 / 5, 3 / 4]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
            }}>
              {RichText.render(page.content)}
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Terms

export const query = graphql`
query TermsQuery {
  prismic {
    terms(lang: "en-us", uid: "terms") {
      title
      content
      meta_title
      meta_description
      _linkType
    }
  }
}
`